declare const $: any;

export function init(): void
{
	const ajaxUrl = '/wp-admin/admin-ajax.php';

	$('.read-more-posts a').on('click', function() {

		var nextPage = parseInt($(this).data('next-page'), 10);
		var max = parseInt($(this).data('max'), 10);

		$.ajax({
			url: ajaxUrl,
			type: 'POST',
			data: { 
				action: 'content-blog-load-more-posts',
				nextPage: nextPage,
				categories: $(this).data('categories'),
				postsPerPage: $(this).data('posts-per-page')
			},
			dataType: 'json',
			success: onSuccess,
			error: onError
		});

		$(this).data('next-page', ++nextPage);

		if (nextPage > max)
			$(this).fadeOut();

		return false;
	});

	function onSuccess(data) {
	}

	function onError(error) {
		console.log(error);
	}

	var oldHeight = {};

	$('#content-blog-container').on('mouseenter', '.blog-post', function() {
		
		var post = $(this).find('.blog-post-overlay');

		if (oldHeight[$(this).attr('id')] == null)
		{
			oldHeight[$(this).attr('id')] = post.outerHeight();
    	}

    	post.stop().animate({'height': '100%'});
    	$(this).find('.post-read-more').stop().animate({'top': '50%'});
  	})
  	.on('mouseleave', '.blog-post', function() {
  		var height = oldHeight[$(this).attr('id')];
    	$(this).find('.blog-post-overlay').stop().animate({height: height});
    	$(this).find('.post-read-more').stop().animate({'top': '100%'});
  	});
}
